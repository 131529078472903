.active-icon svg * {
    stroke: #FFF;
}

.active-icon svg circle {
    fill: rgb(252, 143, 0);
}

.svg-wrapper-h100 svg {
    height: 80px;
    width: 80px;
}

.tooltip-down-arrow:before {
    content: '';
    display: block;
    position: absolute;
    left: calc(50% - 8px);
    top: 99%;
    width: 0;
    height: 0;
    border: 8px solid transparent;
    border-top-color: rgb(26, 118, 248);
}

.blur-bg .chat-bubble-card {
    position: relative;
    /* background: rgba(255, 255, 255, 0.7) !important; */
    padding-top: 80px;
    text-align: center;
    padding-bottom: 80px;
    background-image: url('../../images/overlay-image.png');
}

.blur-bg .chat-bubble-card::before {
    content: "Day 1: Gentle Warm-up and Joint MobilityBegin with a gentle warm-up, including neck, shoulder, wrist, ankle, and toe movements.Perform joint mobilization exercises to increase mobility and lubrication.Finish with deep breathing and relaxation.Day 2: Seated Yoga for Knee MobilityFocus on seated poses that promote knee mobility and flexibility.Include gentle forward folds and seated twists.Conclude with a short meditation or relaxation.Day 3: Standing Poses for Leg StrengthEmphasize standing poses that strengthen leg muscles and stabilize the knees.Include Warrior I, Warrior II, and Chair Pose.Finish with a gentle cool-down and relaxation.Day 4: Yoga for Flexibility and Range of MotionPractice poses that enhance flexibility and range of motion.Include Extended Triangle Pose, Extended Side Angle Pose, and Garland Pose.End with a relaxation pose like Child's Pose or Supine Bound Angle Pose.Day 5: Restorative Yoga and Gentle StretchingFocus on restorative poses for deep relaxation and tension release.Include Supported Bridge Pose, Legs-Up-the-Wall Pose, and gentle stretches.Conclude with guided relaxation or meditation.Day 6: Yoga for Balance and StabilityIncorporate balancing poses like Half Moon Pose, Eagle Pose, and Dancer's Pose.Practice gentle leg strengthening exercises such as Squats and Lunges.Finish with seated meditation or breathing exercises.Day 7: Rest and RecoveryTake a day for rest and gentle movements.Engage in activities like walking, Tai Chi, or a slow-paced yoga flow.Allow time for reflection and gratitude.";
    position: absolute;
    color: transparent;
    top: 15px;
    text-shadow: 0 0 13px #000;
    left: 15px;
}