.sm-container {
    max-width: 460px;
    margin: 0 auto;
}

.bg-yoga-ai {
    background: url('../images/Yogi-AI-bg.svg');
    background-size: cover;
}

.chat-bubble-card {
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05) !important;
    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05) !important;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05) !important;
    max-width: calc(100% - 50px)
}

.yogi-bubble {
    border-radius: 16px !important;
    border-top-left-radius: 0 !important;
    background-color: #FFFAF5 !important;
    border: 2px solid #FFF;
}

.user-bubble {
    border-radius: 12px !important;
    border-top-right-radius: 0 !important;
}

.smooth-scroll {
    scroll-behavior: smooth;
}

.svg-wrapper-h50 svg {
    height: 50px;
    width: 50px;
}

@tailwind base;
@tailwind components;
@tailwind utilities;


.typing {
    align-items: center;
    display: flex;
    height: 17px;
}

.typing .dot {
    animation: mercuryTypingAnimation 1.8s infinite ease-in-out;
    background-color: #6CAD96;
    border-radius: 50%;
    height: 7px;
    margin-right: 4px;
    vertical-align: middle;
    width: 7px;
    display: inline-block;
}

.typing .dot:nth-child(1) {
    animation-delay: 200ms;
}

.typing .dot:nth-child(2) {
    animation-delay: 300ms;
}

.typing .dot:nth-child(3) {
    animation-delay: 400ms;
}

.typing .dot:last-child {
    margin-right: 0;
}

@keyframes mercuryTypingAnimation {
    0% {
        transform: translateY(0px);
        background-color: #6CAD96;
    }

    28% {
        transform: translateY(-7px);
        background-color: #9ECAB9;
        ;
    }

    44% {
        transform: translateY(0px);
        background-color: #B5D9CB;
    }
}


.image-bottom-overlay {
    background: rgb(0, 0, 0);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.0) 0%, rgba(0, 0, 0, 0.51) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000", endColorstr="#000000", GradientType=1);
    height: 80px;
    width: 100%;
    bottom: 0;
    position: absolute;
}